import { utmListLocalStorageKey, gaIdCookieKey } from '@/plugins/profit-metrics.client'
import { useLocaleStore } from "@/stores/locale"
import localStorageKeys from '@/constants/local-storage-keys'
import expirations from '@/constants/expirations'

export const useBookStore = defineStore('book', {
  state: () => ({
    stateObjects: [],
    tripinfo: null,
    roomtypes: null,
    hotelOptions: {},
    bookinfo: null,
    activeStep: -1,
    highestStep: -1,
    isRoundtrip: true,

    showWaitinglist: false,

    modalRoomTypes: [],

    error: null,

    priceIncrease: 0,

    numberOfPeople: null,
    rooms: null,
    selectedAirport: null,
    selectedRoomGroup: {},
    selectedRooms: [],

    timeout: 30 * 1000,

    travelers: [],
    manager: null,
    luggageCost: 0
  }),
  persist: {
    paths: [
      'activeStep',
      'stateObjects',
      'tripinfo',
      'bookinfo',
      'roomtypes',
      'hotelOptions',
      'numberOfPeople',
      'isRoundtrip',
      'luggageCost',
      'travelers',
      'manager',
      'rooms',
      'step'
    ],
    storage: persistedState.localStorageWithExpiration({ expiration: expirations.hour }),
  },
  getters: {
    transferOptionArrivalSelectedObject (state) {
      return state.tripinfo?.transfer_options_arrival?.find(transfer => transfer.rateKey === state.tripinfo.transfer_option_arrival_selected)
    },

    transferOptionReturnSelectedObject (state) {
      return state.tripinfo?.transfer_options_return?.find(transfer => transfer.rateKey === state.tripinfo.transfer_option_return_selected)
    }
  },
  actions: {
    //old mutations
    RESET_STATEOBJECT () {
      this.stateObjects = []
    },

    SET_STATEOBJECTS (data) {
      this.stateObjects = data
    },

    SET_STATEOBJECT ({ stateObject, step, clear }) {
      this.stateObjects[step] = stateObject

      if (clear) {
        this.stateObjects.splice(step + 1)
      }
    },

    SET_STEP (step) {
      this.activeStep = step
      this.highestStep = step === 0 ? step
        : (this.highestStep < step ? step : this.highestStep)
    },

    SET_HIGHESTSTEP (step) {
      this.highestStep = step === 0 ? step
        : (this.highestStep < step ? step : this.highestStep)
    },

    SET_TRIPINFO (tripinfo) {
      this.tripinfo = tripinfo
    },

    SET_BOOKINFO (bookinfo) {
      this.bookinfo = bookinfo
    },

    SET_ROOMTYPES (roomtypes) {
      this.roomtypes = roomtypes
    },

    SET_HOTELOPTIONS ({ hotels, minimumPrice, recommended }) {
      if (!hotels?.length) {
        return
      }

      const hotelOptions = {
        ...this.hotelOptions
      }
      hotelOptions.recommended = recommended
      hotelOptions.minimumPrice = minimumPrice
      hotelOptions.hotels = hotels.filter(h => !!h.meta?.Rating)
      this.hotelOptions = hotelOptions
    },

    RESET_HOTELOPTIONS () {
      this.hotelOptions = {}
    },

    SET_ROOMS (rooms) {
      this.rooms = rooms
    },

    SET_NUMBEROFPEOPLE (numberOfPeople) {
      this.numberOfPeople = numberOfPeople
    },

    SET_ROOMGROUP (data) {
      this.selectedRoomGroup = data
    },

    SET_SELECTED_ROOMS (data) {
      this.selectedRooms = data
    },

    SET_TIMEOUT (timeout) {
      this.timeout = timeout * 1000
    },

    SET_TRAVELERS (travelers) {
      this.travelers = travelers
    },

    SET_MANAGER (manager) {
      this.manager = manager
    },

    SET_ERROR (error) {
      this.error = error
    },

    SET_PRICEINCREASE (data) {
      this.priceIncrease = data
    },

    SET_LUGGAGECOST (data) {
      this.luggageCost = data
    },

    SET_ISROUNDTRIP (data) {
      this.isRoundtrip = data
    },

    SET_SELECTED_AIRPORT (data) {
      this.selectedAirport = data
    },

    SET_SHOWWAITINGLIST (data) {
      this.showWaitinglist = data
    },

    SET_MODALROOMTYPES (data) {
      this.modalRoomTypes = data
    },

    async getDataFromSSN ({ ssn, f }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      let params = ''

      if (f) {
        params += `&f=1`
      }

      const { data: result } = await apiFetch(`/${locale}/booking/pnum?p=${ssn}${params}`)

      return result
    },

    async getDataFromPhone (phone) {
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale

      if (!phone || !rootStore.showOfficeTools) {
        return
      }

      const { data: result } = await apiFetch(`/${locale}/reseller/customer-data-from-tel/${rootStore.agentCookie.code}/${phone}`)

      return result
    },

    // Step 0
    async init ({ tripid, initialPrice, singlePrice }) {
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale

      this.SET_STEP(-1)
      this.SET_ISROUNDTRIP(true)
      this.RESET_STATEOBJECT()
      this.SET_TRAVELERS([])
      this.SET_ROOMS(this.rooms ? this.rooms : null)
      this.SET_ROOMGROUP({})
      this.SET_MANAGER(null)
      this.SET_LUGGAGECOST(0)
      this.SET_NUMBEROFPEOPLE(null)
      this.SET_BOOKINFO(null)
      this.RESET_HOTELOPTIONS()
      try {
        localStorage.removeItem(localStorageKeys.extraLuggage)
      } catch {
        //
      }


      const { data: result } = await apiFetch(`/${locale}/booking/new-booking/${tripid}/${initialPrice || ''}${singlePrice ? `/${singlePrice}` : ''}`)

      this.SET_STATEOBJECT({ stateObject: result.state, step: 0 })
      this.SET_TRIPINFO({
        tripid,
        triptypeid: result.triptypeid,
        tripdate: result.tripdate,
        tripname: result.tripname,
        returndate: result.returndate,
        maxpax: result.maxpax,
        roomstypes_needed: result.roomstypes_needed,
        dynamic_flights: result.dynamic_flights,
        image: result.main_picture_cloudinary,
      })

      this.SET_STEP(0)

      if (result.dynamic_flights) {
        await rootStore.fetchCharterAirports()
      }

      this.SET_TIMEOUT(result.bprocess_timeout)

      await rootStore.fetchAgentCall({})

      return result
    },

    // Step 1
    //TODO: test this function
    async roomTypes (info) {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale

      info.state = this.stateObjects[0]

      let result

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      try {
        const { data: res } = await apiFetch(`/${locale}/booking/roomtypes`, {
          method: 'post',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: info
        })

        result = res

        if (result.require_property_list) {
          const { data: propertyResult } = await apiFetch(`/${locale}/charter/property_list`, {
            method: 'post',
            body: {
              state: result.state,
              flight_offer_id: result.flight_offer_id,
            }
          })

          this.SET_TRIPINFO({
            ...this.tripinfo,
            hoteltype_needed: true,
            priceChangedSinceStartUpdated: propertyResult.priceChangedSinceStart,
          })

          if (propertyResult.property_list.length) {
            this.SET_HOTELOPTIONS({
              id: propertyResult.flight_offer_id,
              recommended: propertyResult.recommended_properties,
              minimumPrice: propertyResult.minimum_package_price,
              hotels: propertyResult.property_list,
            })

            result.state = propertyResult.state
          }
        }
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }

        if (e.data?.waitinglist) {
          this.SET_SHOWWAITINGLIST(e.data.message)
        }

        throw e
      }

      if (result.airport_dictionary) {
        const airportNames = {}

        result.airport_dictionary.forEach((a) => {
          airportNames[a.iata] = a
        })

        rootStore.SET_CHARTER_AIRPORT_NAMES(airportNames)
      }

      this.SET_STATEOBJECT({ stateObject: result.state, step: 1 })
      this.SET_TRIPINFO({
        ...this.tripinfo,
        flightOffer: result.flightOffer,
        luggage_policies: result.luggage_policies,
        pricing: result.pricing,
        price: result.price,
        priceInfo: result.price_info,
        priceBase: result.price,
        roomtypes_grouped_per_room: result.roomtypes_grouped_per_room,
        priceChangedSinceStart: result.inital_price_changed,
        pax_exception_codes: result.pax_exception_codes,
        airport: result.airport,
        selected_rooms: result.selected_rooms,
      })
      this.SET_ROOMTYPES(result.rooms)
      this.SET_SELECTED_ROOMS([])
      this.SET_ROOMGROUP({})
      this.SET_NUMBEROFPEOPLE(null)

      this.SET_STEP(1)

      return result
    },

    async roomData (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      info.state = this.stateObjects[1] || this.stateObjects[0]

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      const { data: result, error } = await apiFetch(`/${locale}/booking/roomtypes-for-hotel`,{
        method: 'post',
        ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
        body: info
      }, false)
      if(error) {
        this.SET_MODALROOMTYPES(result?.roomtypes)
        this.SET_STATEOBJECT({ stateObject: info.state, step: 1 })
      }else {
        this.SET_MODALROOMTYPES(result.roomtypes)
        this.SET_STATEOBJECT({ stateObject: result.state, step: 1 })
      }

      return result
    },

    async hotelInfo (giataId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      const { data: result } = await apiFetch(`/${locale}/booking/property-info/${giataId}`, {
        ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
      })

      return result
    },

    // Step 2
    async customerData (info) {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const locale = localeStore.locale

      if (info.state) {
        this.SET_STATEOBJECT({ stateObject: info.state, step: 1 })
      }
      info.state = this.stateObjects[1] || this.stateObjects[0]

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      let result
      try {
      const { data: res } = await apiFetch(`/${locale}/booking/customer-data`, {
        method: 'post',
        ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: info
        })
        result = res

      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }
        throw e
      }

      let extraData = {}

      if (result.tripid) {
        extraData = {
          server_tripid: result.tripid,
          tripdate: result.tripdate,
          tripname: result.tripname,
          pricing: result.pricing,
          price: result.price,
          returndate: result.returndate
        }
      }

      if (result.priceInfo) {
        extraData.priceInfo = result.priceInfo
      }

      if (result.numpax) {
        this.SET_NUMBEROFPEOPLE(result.numpax)
      } else {
        this.SET_NUMBEROFPEOPLE(null)
      }

      // When going to back to room/traveler selection, reset travelers
      this.SET_TRAVELERS([])
      this.SET_STATEOBJECT({ stateObject: result.state, step: 2, clear: true })
      this.SET_TRIPINFO({
        ...this.tripinfo,
        ...extraData,
        flightOffer: result.flightOffer,
        luggage_policies: result.luggage_policies,
        selected_rooms: result.selected_rooms,
        socialsecuritynumber_needed: result.socialsecuritynumber_needed,
        required_full_name_according_to_passport: result.required_full_name_according_to_passport,
        ask_for_ssn: result.ask_for_ssn || result.socialsecuritynumber_needed,
        passportinfo_needed: result.passportinfo_needed,
        email_required: result.email_required,
        dob_needed: result.dob_needed,
        nationality_needed: result.nationality_needed,
        connecting_journey: result.connecting_journey,
        possible_pickups: result.possible_pickups,
        pricing: result.pricing,
        price: result.price,
        customerinfo: result.customerinfo,
        extras: result.extras,
        priceChangedSinceStart: result.priceChangedSinceStart,
        paymentDates: result.paymentDates,
        paymentNow: result.paymentNow,
        transfer_options_arrival: result.transfer_options_arrival,
        transfer_options_return: result.transfer_options_return,
        airport: result.airport,
        pax_exception_codes: result.pax_exception_codes,
        show_copy_bookmanager: result.show_copy_bookmanager,
        show_possible_pickups: result.show_possible_pickups,
        raw: result
      })

      return result
    },

    // Step 3
    async addressData (info) {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const locale = localeStore.locale

      info.state = this.stateObjects[2]

      let result

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      try {
        const { data: res } = await apiFetch(`/${locale}/booking/final-confirmation`,{
          method: 'post',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: info
        })
        result = res
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }
        throw e
      }

      this.SET_STATEOBJECT({ stateObject: result.state, step: 3 })

      // Reset luggage backup
      this.SET_STATEOBJECT({ stateObject: null, step: 99 })
      try {
        localStorage.removeItem(localStorageKeys.extraLuggage)
      } catch {
        //
      }

      this.SET_LUGGAGECOST(0)
      this.SET_BOOKINFO(result.booking)
      this.SET_TRIPINFO({
        ...this.tripinfo,
        price: result.price,
        priceChangedSinceStart: result.priceChangedSinceStart,
        customerinfo: result.customerinfo,
        originalFlightOffer: result.flightOffer,
        flightOffer: result.flightOffer,
        flightPricingIncluded: result.flightPricingIncluded,
        paymentDates: result.paymentDates,
        paymentNow: result.paymentNow,
        extras: result.extras,
        extra_costs: result.extra_costs,
        insuranceproducts: result.insuranceproducts,
        insurance_options: result.insurance_options,
        transfer_options_arrival: info.transfer_options_arrival,
        transfer_options_return: info.transfer_options_return,
        transfer_option_arrival_selected: result.transfer_option_arrival_selected?.rateKey,
        transfer_option_return_selected: result.transfer_option_return_selected?.rateKey,
        how_found_trip_options: result.how_found_trip_options,
        allow_confirmation_without_payment: result.allow_confirmation_without_payment,
        allow_open_requests: result.allow_open_requests,
        allow_send_invoice_on_completion: result.allow_send_invoice_on_completion
      })

      this.SET_STEP(3)

      return result
    },

    async confirmLuggage (info) {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const locale = localeStore.locale
      info.state = this.stateObjects[3]

      // 99 is the backup state
      if (this.stateObjects[99]) {
        info.state = this.stateObjects[99]
      } else {
        this.SET_STATEOBJECT({ stateObject: this.stateObjects[3], step: 99 })
      }

      if (!info.selected_luggage.length) {
        this.SET_STATEOBJECT({ stateObject: info.state, step: 3 })

        this.SET_TRIPINFO({
          ...this.tripinfo,
          flightOffer: this.tripinfo.originalFlightOffer
        })
        return
      }

      let result

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      try {
        const { data: res } = await apiFetch(`/${locale}/booking/confirm-luggage`, {
          method: 'post',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: info
        })
        result = res
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }
        throw e
      }

      this.SET_TRIPINFO({
        ...this.tripinfo,
        flightOffer: result.flightOffer
      })

      this.SET_STATEOBJECT({ stateObject: result.state, step: 3 })

      return result
    },

    // Step 4
    async paymentData (info) {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const locale = localeStore.locale
      info.state = this.stateObjects[3]

      let url = `/${locale}/booking/get-payment-data`

      if (!info.final_confirmation) {
        info.final_confirmation = this.bookinfo.final_confirmation
        info.skip = this.bookinfo.skip_payment
      }

      if (info.skip) {
        url += '?skip_payment=1'
      }

      let result

      const utmList = JSON.parse(localStorage?.getItem(utmListLocalStorageKey))
      const gaId = useCookie(gaIdCookieKey)

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      try {
        const { data: res } = await apiFetch(url,{
          method: 'post',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: {
            final_confirmation: info.final_confirmation,
            user_host: window.location.host,
            protocol: window.location.protocol,
            state: info.state,
            ...(utmList ? { utmList } : {}),
            ...(gaId ? { gaId } : {}),
          }
        })
        result = res
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }
        throw e
      }

      this.SET_BOOKINFO({
        ...this.bookinfo,
        final_confirmation: info.final_confirmation,
        skip_payment: info.skip
      })

      this.SET_STATEOBJECT({ stateObject: result.state, step: 4 })
      if (result.ocr) {
        this.SET_BOOKINFO({
          ...this.bookinfo,
          ocr: result.ocr
        })
      }

      if (result.bambora_token) {
        this.SET_BOOKINFO({
          ...this.bookinfo,
          bambora_token: result.bambora_token
        })
      }

      if (!result.priceIncrease) {
        // step 5 set in fetch to prevent double mounting
        this.SET_STEP('loading')
      }

      return result
    },

    // Step 5
    async finalize () {
      const localeStore = useLocaleStore()
      const charterStore = useCharterStore()
      const locale = localeStore.locale

      const info = {
        state: this.stateObjects[4],
      }

      let result

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      try {
        const { data: res } = await apiFetch(`/${locale}/booking/finalize`,{
          method: 'post',
          ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
          body: info
        })
        result = res
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          charterStore.SET_TIMEDOUT(true)
        }
        throw e
      }

      this.SET_STATEOBJECT({ stateObject: result.state, step: 5 })
      this.SET_STEP(5)
      this.SET_BOOKINFO({
        ...this.bookinfo,
        ocr: result.ocr,
        customerId: result.customerId,
        success: result.success,
        status: result.status,
        payment_link: result.payment_link,
        pending_payments: result.pending_payments
      })

      return result
    },

    async getPreviousBooking (ocr) {
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale

      this.SET_BOOKINFO(null)
      this.SET_TRIPINFO(null)

      // if (process.browser) {
      //   if (window.cab) {
      //     window.cab.abort()
      //   }

      //   window.cab = new AbortController()
      //   abortTimeout(window.cab, this.timeout)
      // }

      const { data: result } = await apiFetch(`/${locale}/booking/get-previous-booking/${ocr}`, {
        ...(import.meta.browser ? { signal: createTimeoutSignal(this.timeout) } : {}),
      })

      this.SET_BOOKINFO({
        ...result.final_confirmation.booking,
        how_found_trip: result.how_found_trip,
        bookable: result.bookable,
        bambora_token: result.bambora_token
      })

      this.SET_TRIPINFO({
        ...result.final_confirmation
      })

      if (result.airport_dictionary) {
        const airportNames = {}

        result.airport_dictionary.forEach((a) => {
          airportNames[a.iata] = a
        })

        rootStore.SET_CHARTER_AIRPORT_NAMES(airportNames)
      }

      return result
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBookStore, import.meta.hot))
}
